import { mapGetters, mapActions } from 'vuex';
import FilterSelect from "./filterSelect/filterSelect.vue";
import FilterTag from "./filterTag/filterTag.vue";
import { convertCountryCodeToLanguageCode, convertLanguageCodeToCountryCode } from '@/utils/language';
import { config } from '@/config/config';
import { SUPPORTED_FILE_SIZES, SUPPORTED_SEARCH_CATEGORIES } from '@/utils/constants';

const i18nComputedProperties = {
  i18nFilterPanelTitleText() {
    return this.$t('downloadcenter.search.filter.title');
  },
  i18nCategoriesFilterName() {
    return this.$t('downloadcenter.search.filter.category', 'Kategorie');
  },
  i18nLanguagesFilterName() {
    return this.$t('downloadcenter.search.filter.language', 'Sprache');
  },
  i18nFileSizeFilterName() {
    return this.$t('downloadcenter.search.filter.filesize', 'Dateigröße');
  },
  i18nClearAllTags() {
    return this.$t('downloadcenter.search.filter.clearalltags');
  },
};

export default {
  components: {
    FilterSelect,
    FilterTag,
  },
  computed: {
    ...mapGetters('searchModule', ['queryLanguage', 'queryCategory', 'queryFileSize', 'searchInProgress']),
    ...i18nComputedProperties,
    languageTranslationKeys() {
      const mappedLanguages = config.availableLanguages.map((languageKey) => {
        return {
          key: convertCountryCodeToLanguageCode(languageKey),
          value: this.$t(`downloadcenter.search.languages.${languageKey}`),
        };
      });
      return mappedLanguages;
    },
    categoryTranslationKeys() {
      return this.createFilterKeysFromStoreData('documents', SUPPORTED_SEARCH_CATEGORIES);
    },
    fileSizeTranslationKeys() {
      return SUPPORTED_FILE_SIZES.map((fileSize) => {
        return {
          key: fileSize.key,
          value: this.$t(`downloadcenter.search.filesize.${fileSize.key}`),
        };
      });
    },
    categoryTagsArray() {
      return this.createFilterKeysFromStoreData('documents', this.queryCategory);
    },
    languageTagsArray() {
      return this.createFilterKeysFromStoreData('languages', this.queryLanguage, true);
    },
    fileSizeTagsArray() {
      return this.createFilterKeysFromStoreData('filesize', this.queryFileSize);
    },
    hasTags() {
      return this.fileSizeTagsArray?.length || this.languageTagsArray?.length || this.categoryTagsArray?.length;
    },
  },
  methods: {
    ...mapActions('searchModule', ['setCurrentPage', 'setQueryCategory', 'setQueryLanguage', 'setQueryFileSize', 'deleteQueryCategory', 'deleteQueryLanguage', 'deleteQueryFileSize', 'clearSearchFilters']),
    clearAllTags() {
      this.clearSearchFilters();
      this.setCurrentPage(1);
    },
    createFilterKeysFromStoreData(filterName, storeData, convertKey) {
      return storeData.map((item) => {
        let i18nTagName = item;
        if (convertKey && filterName === 'languages') i18nTagName = convertLanguageCodeToCountryCode(item);
        return {
          key: item,
          value: this.$t(`downloadcenter.search.${filterName}.${i18nTagName.toLowerCase()}`),
        };
      });
    },
  },
};