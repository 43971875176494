var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notFoundSection" }, [
    _c("div", { staticClass: "notFoundSection__wrapper" }, [
      _c("div", { staticClass: "notFoundSection__404Wrapper" }, [
        _c("span", { staticClass: "notFoundSection__404Main" }, [
          _vm._v("404")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "notFoundSection__404Additional" }, [
          _vm._v(_vm._s(_vm.i18n404Additional))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "notFoundSection__textSectionWrapper" }, [
        _c("div", { staticClass: "notFoundSection__textSectionTitle" }, [
          _c("div", [_vm._v(_vm._s(_vm.i18nTextSectionFirstTitle))]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.i18nTextSectionSecondTitle))])
        ]),
        _vm._v(" "),
        _vm.shouldDisplayTextSection
          ? _c("div", [
              _c(
                "p",
                { staticClass: "notFoundSection__textSectionParagraph" },
                [_vm._v(_vm._s(_vm.i18nTextSectionFirstParagraph))]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "notFoundSection__textSectionParagraph" },
                [_vm._v(_vm._s(_vm.i18nTextSectionSecondParagraph))]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }