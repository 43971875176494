import HeaderSection from '@/components/headerSection/headerSection.vue';
import SearchResult from '@/components/searchResult/searchResult.vue';
import FooterSection from '@/components/footerSection/footerSection.vue';

export default {
  components: {
    HeaderSection,
    SearchResult,
    FooterSection,
  },
};