import { mapGetters, mapActions } from 'vuex';
import PaginationItem from "./paginationItem/paginationItem.vue";

const i18nComputedProperties = {
  i18nPrevPageText() {
    return this.$t('downloadcenter.nav.pagination.prev', 'vorherige');
  },
  i18nNextPageText() {
    return this.$t('downloadcenter.nav.pagination.next', 'nächste');
  },
};

export default {
  components: {
    PaginationItem,
  },
  computed: {
    ...mapGetters('searchModule', ['currentPage', 'amountPages']),
    ...i18nComputedProperties,
    shouldShowPagination() {
      return !!this.amountPages && this.amountPages > 1;
    },
    shouldShowPrevPageButton() {
      return this.currentPage > 1;
    },
    shouldShowNextPageButton() {
      return this.currentPage < this.amountPages;
    },
    hasMinAmountForDots() {
      return this.amountPages > 6;
    },
    shouldShowFirstDots() {
      return this.hasMinAmountForDots && (this.currentPage - 3 > 1);
    },
    shouldShowLastDots() {
      return this.hasMinAmountForDots && (this.currentPage + 3 < this.amountPages);
    },
    paginationItemsList() {
      if (!this.shouldShowPagination) return ;

      if (this.shouldShowFirstDots && this.shouldShowLastDots) {
        return this.getArrayFromRange(this.currentPage - 1, this.currentPage + 2);
      }

      if (this.shouldShowFirstDots) {
        return this.getArrayFromRange(this.amountPages - 4, this.amountPages);
      }

      if (this.shouldShowLastDots) {
        return this.getArrayFromRange(2, 6);
      }
      
      return this.getArrayFromRange(2, this.amountPages);
    },
  },
  methods: {
    ...mapActions('searchModule', ['setCurrentPage']),
    handleNextPageClick() {
      this.setCurrentPage(this.currentPage + 1);
    },
    handlePrevPageClick() {
      this.setCurrentPage(this.currentPage - 1);
    },
    getArrayFromRange(start, end) {
      return [...Array(end - start)].map((_, i) => start + i);
    },
  },
};