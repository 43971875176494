var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { "styles-root": "" } },
    [
      _c("router-view"),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "modalPortal" } }),
      _vm._v(" "),
      _vm.shouldShowCookieNotificationModal
        ? _c("cookieNotificationModal")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }