import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    shouldShowSelect: {
      type: Boolean,
    },
  },
  data() {
    return {
      isSelectOpened: false,
    };
  },
  computed: {
    ...mapGetters('searchModule', ['searchMode', 'supportedSearchModes', 'query', 'searchInProgress']),
    currentSearchMode: {
      get() {
        return this.searchMode;
      },
      set(value) {
        this.setSearchMode(value);
      },
    },
    searchQuery: {
      get() {
        return this.query;
      },
      set(value) {
        this.setQuery(value);
      },
    },
    searchModeTranslationKeys() {
      return this.supportedSearchModes.map((mode) => {
        return {
          lang: this.$t(`downloadcenter.search.mode.${mode}`, 'Alle Arten'),
          key: mode,
        };
      });
    },
    selectedOptionTranslation() {
      const selectedOption = this.searchMode || this.searchModeTranslationKeys[0].lang;
      return this.$t(`downloadcenter.search.mode.${selectedOption}`, 'Alle Arten');
    },
    searchSubmit() {
      return this.$t('downloadcenter.search.submit', 'Suchen');
    },
    searchPlaceholder() {
      return this.searchMode === 'AUTO' ? this.$t('downloadcenter.search.placeholder', '...') : this.$t(`downloadcenter.search.mode.${this.searchMode}`, '...');
    },
    selectArrowIcon() {
      return this.isSelectOpened ? 'arrow-up' : 'arrow-down';
    },
  },
  methods: {
    ...mapActions('searchModule', ['setSearchMode', 'setCurrentPage', 'setQuery']),
    handleNavigation() {
      if (this.searchInProgress) return;
      if (this.$route.name !== 'search') this.$router.push('/search');
      this.setCurrentPage(1);
    },
    handleOptionSelection(option) {
      this.setSearchMode(option);
      this.isSelectOpened = false;
    },
    focusSearchInput() {
      if (this.query) return;
      this.$refs.search.focus();
    },
  },
  async mounted() {
    await this.$nextTick();
    this.focusSearchInput();
  },
};