const storageActions = {
  getItem: 'getItem',
  removeItem: 'removeItem',
  setItem: 'setItem',
};
const cookieKeysWithUnlimitedExpiration = [];
const cookieKeysForSubdomains = [];
const rejectionExpirationMilliseconds = 7 * 24 * 60 * 60 * 1000;

let storageErrorMsgShown = false;
const doStorageAction = (storage, action, parameters, reTry) => {
  try {
    return storage[action](...parameters);
  } catch (error) {
    if (reTry) {
      throw error;
    }
    if (!storageErrorMsgShown) {
      storageErrorMsgShown = true;
    }
    try {
      sessionStorage.clear();
      localStorage.clear();
    } catch (e) {
    }
    return doStorageAction(storage, action, parameters, true);
  }
};

const sessionSet = (item, value) => {
  doStorageAction(sessionStorage, storageActions.setItem, [item, value]);
};

const sessionGet = item =>
  doStorageAction(sessionStorage, storageActions.getItem, [item]);

const getCookie = item => {
  const cookieValue = new RegExp(`(?:^|; )${item}=([^;]*)`).exec(
    document.cookie,
  );
  if (cookieValue) {
    return cookieValue[1];
  }
  return null;
};

const setCookie = (item, value) => {
  const expirationDays = 30;
  let expiration;
  let domain = '';
  if (cookieKeysWithUnlimitedExpiration.includes(item)) {
    expiration = new Date(2038, 1, 19, 4, 14, 0, 0); // max allowed cookie age for 32-bit int
  } else {
    expiration = new Date();
    expiration.setTime(
      expiration.getTime() + expirationDays * 24 * 60 * 60 * 1000,
    );
  }
  if (cookieKeysForSubdomains.includes(item)) {
    domain = `; domain=${document.domain.match(/[^.]*\.[^.]*$/)?.[0] || 'localhost'};`;
  }
  document.cookie = `${item}=${value}; expires=${expiration.toUTCString()}; path=/; Secure; SameSite=Strict${domain}`;
};

const removeCookie = item => {
  if (getCookie(item)) {
    let domain = '';
    if (cookieKeysForSubdomains.includes(item)) {
      domain = `; domain=${document.domain.match(/[^.]*\.[^.]*$/)[0]};`;
    }
    document.cookie = `${item}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/${domain}`;
  }
};

const localSet = (item, value) => {
  doStorageAction(localStorage, storageActions.setItem, [item, value]);
  setCookie(item, value);
  sessionSet(item, value);
};

const localGet = item => {
  const value = doStorageAction(localStorage, storageActions.getItem, [item]);
  if (!value) {
    const cookieMainValue = getCookie(item);
    if (cookieMainValue) {
      localSet(item, cookieMainValue);
      return cookieMainValue;
    } else {
      return sessionGet(item);
    }
  }
  return value;
};


const disableGAString = `ga-disable-GTM-5KBLZWD`;

const optInGA = () => {
  window[disableGAString] = false;
};

const optOutGA = () => {
  window[disableGAString] = true;
};

const cookieNotificationClosedStorageKey = 'cookieNotificationClosed';
cookieKeysWithUnlimitedExpiration.push(cookieNotificationClosedStorageKey);
cookieKeysForSubdomains.push(cookieNotificationClosedStorageKey);
const getCookieNotificationClosed = () => {
  return localGet(cookieNotificationClosedStorageKey) ? true : false;
};
const setCookieNotificationClosed = () => {
  localSet(cookieNotificationClosedStorageKey, 'yes');
};

const cookiesGloballyAcceptedKey = 'cookiesGloballyAccepted';
cookieKeysWithUnlimitedExpiration.push(cookiesGloballyAcceptedKey);
cookieKeysForSubdomains.push(cookiesGloballyAcceptedKey);
const getCookiesGloballyAccepted = () => {
  const value = localGet(cookiesGloballyAcceptedKey);
  if (value === null) {
    return null;
  }
  return value === 'yes';
};
const setCookiesGloballyAccepted = accepted => {
  localSet(cookiesGloballyAcceptedKey, accepted ? 'yes' : 'no');
};

const cookieRejectionTimestampKey = 'cookieRejectionTimestamp';
cookieKeysWithUnlimitedExpiration.push(cookieRejectionTimestampKey);
cookieKeysForSubdomains.push(cookieRejectionTimestampKey);
const getCookieRejectionTimestamp = () => localGet(cookieRejectionTimestampKey);
const setCookieRejectionTimestamp = timestamp => {
  localSet(cookieRejectionTimestampKey, timestamp);
};

const cookiesGloballyAccepted = getCookiesGloballyAccepted();
const gaOptOutCookie = 'google_analytics_tracking_disabled';

const isCookieRejectionExpired = () => {
  const cookieRejectionTimestamp = getCookieRejectionTimestamp();
  if (!cookieRejectionTimestamp) {
    return true;
  }
  return (
    Date.now() - cookieRejectionTimestamp > rejectionExpirationMilliseconds
  );
};

const isBot = () => {
  const c =
    '(Googlebot|Bingbot|Slurp|DuckDuckBot|BingPreview|Baiduspider|YandexBot|Exabot|facebookexternalhit|SearchmetricsBot|facebot|ia_archiver|Twitterbot|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest|slackbot|vkShare|W3C_Validator|Msnbot|Sistrix crawler|Ahrefsbot|Seznambot|SemrushBot|AdsBot-Google|conrad-prerenderer|Google-Structured-Data-Testing-Tool|Blog-Conrad-AT-Plugin|WhatsApp)';
  const a = new RegExp(c, 'i');
  const b = navigator.userAgent;
  return a.test(b);
};

const updateGATrackingStatus = () => {
  if (!getCookiesGloballyAccepted()) {
    optOutGA();
    setCookie(gaOptOutCookie, '1');
    return;
  }

  const gaOptOut = getCookie(gaOptOutCookie);
  if (!!gaOptOut && gaOptOut !== '1') {
    optInGA();
    removeCookie(gaOptOutCookie);
  }
};

const shouldShowCookieNotification = () => {
  updateGATrackingStatus();

  if (isBot()) {
    return false;
  }

  if (
    getCookieNotificationClosed() ||
    (cookiesGloballyAccepted ||
      (cookiesGloballyAccepted === false && !isCookieRejectionExpired()))
  ) {
    return false;
  }
  return true;
};

const acceptCookiesGlobally = () => {
  optInGA();
  const googleOptOut = getCookie(gaOptOutCookie);
  if (googleOptOut === '1') {
    removeCookie(gaOptOutCookie);
  }
  setCookiesGloballyAccepted(true);
};

const rejectCookiesGlobally = () => {
  setCookiesGloballyAccepted(false);
  optOutGA();
};

export {
  shouldShowCookieNotification,
  acceptCookiesGlobally,
  rejectCookiesGlobally,
  setCookieNotificationClosed,
  setCookieRejectionTimestamp,
  getCookiesGloballyAccepted,
};
