export default {
  props: {
    pageNumber: {
      type: Number,
      default: 1,
    },
    activePage: {
      type: Boolean,
    },
  },
};