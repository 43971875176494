import HeaderSection from '@/components/headerSection/headerSection.vue';
import CategoriesSection from '@/components/categoriesSection/categoriesSection.vue';
import FooterSection from '@/components/footerSection/footerSection.vue';

export default {
  components: {
    HeaderSection,
    CategoriesSection,
    FooterSection,
  },
};