import { mapGetters, mapActions } from 'vuex';
import CustomDropdown from '@/components/customDropdown/customDropdown.vue';
import { SUPPORTED_RESULTS_PER_PAGE } from '@/utils/constants';

const i18nComputedProperties = {
  i18nAllResultsPerPage() {
    return this.$t('downloadcenter.search.resultsperpage.allresults', {
      '0': this.currentResultsLow, 
      '1': this.currentResultsHigh,
      '2': this.foundAssets,
    });
  },
  i18nResultsSelect() {
    return this.$t('downloadcenter.search.resultsperpage.resultsselect');
  },
};

export default {
  components: {
    CustomDropdown,
  },
  computed: {
    ...mapGetters('searchModule', ['currentPage', 'itemsPerPage', 'foundAssets', 'searchInProgress']),
    ...mapGetters('viewportModule', ['isXSmall']),
    ...i18nComputedProperties,
    supportedResultsPerPage() {
      return SUPPORTED_RESULTS_PER_PAGE;
    },
    currentResultsLow() {
      if (!this.foundAssets) return 0;
      return this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    currentResultsHigh() {
      return Math.min(this.currentPage * this.itemsPerPage, this.foundAssets);
    },
  },
  methods: {
    ...mapActions('searchModule', ['setItemsPerPage', 'setCurrentPage']),
    handleItemsPerPageChange(items) {
      this.setItemsPerPage(items);
      this.setCurrentPage(1);
    },
  },
};