var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["filterTag", { "filterTag--activeTag": _vm.activeTag }],
      on: { click: _vm.clickOnCloseTag }
    },
    [
      _vm._v("\n  " + _vm._s(_vm.tagName) + "\n  "),
      _c("icon", {
        staticClass: "filterTag__closeIcon",
        attrs: { name: "close", scale: "30" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }