import Vue from 'vue';
import '@/plugins';
import App from '@/views/app.vue';
import { store } from '@/store';
import { router } from './router';
import { registerGlobalComponents } from '@/utils/registerGlobalComponents';

const initApp = () => {
  const app = new Vue({
    router,
    store,
    ...App,
  });
  registerGlobalComponents();
  app.$mount('#dlc-app');
};

export { initApp }; 
