import { mapGetters } from 'vuex';
import { BENEFITS_LIST, DOWNLOAD_TERMS_URLS, LEGAL_NOTICE_URLS, PRIVACY_POLICY_URLS } from '@/utils/constants';

const i18nComputedProperties = {
  i18nLegalNotice() {
    return this.$t('downloadcenter.footer.legalnotice', 'Impressum');
  },
  i18nPrivacyPolicy() {
    return this.$t('downloadcenter.footer.privacypolicy', 'Datenschutz');
  },
  i18nDownloadTerms() {
    return this.$t('downloadcenter.footer.downloadterms', 'Downloadbedingungen');
  },
};

export default {
  computed: {
    ...mapGetters('i18nModule', ['language']),
    ...i18nComputedProperties,
    isDELanguageSelected() {
      return this.language === 'de';
    },
    benefitsList() {
      return BENEFITS_LIST.map(benefit => this.$t(`downloadcenter.footer.benefits.${benefit}`, '...'));
    },
    downloadTermsUrl() {
      return DOWNLOAD_TERMS_URLS[this.language] || DOWNLOAD_TERMS_URLS['en'];
    },
    legalNoticeUrl() {
      return LEGAL_NOTICE_URLS[this.language] || LEGAL_NOTICE_URLS['en'];
    },
    privacyPolicyUrl() {
      return PRIVACY_POLICY_URLS[this.language] || PRIVACY_POLICY_URLS['en'];
    },
  },
};