import { mapGetters, mapActions } from 'vuex';
import FiltersListItem from './filtersListItem/filtersListItem.vue';
import { convertCountryCodeToLanguageCode, convertLanguageCodeToCountryCode } from '@/utils/language';
import { config } from '@/config/config';
import { SUPPORTED_FILE_SIZES, SUPPORTED_SEARCH_CATEGORIES } from '@/utils/constants';

const i18nComputedProperties = {
  i18nFiltersModalTitle() {
    return this.$t('downloadcenter.search.filtersmodal.title');
  },
  i18nFiltersModalClose() {
    return this.$t('downloadcenter.search.filtersmodal.close');
  },
  i18nFiltersModalBack() {
    return this.$t('downloadcenter.search.filtersmodal.back', 'zurück');
  },
  i18nShowAllResults() {
    return this.$t('downloadcenter.search.filtersmodal.showallresults');
  },
  i18nRemoveAllFilters() {
    return this.$t('downloadcenter.search.filtersmodal.removeallfilters');
  },
  i18nCategoriesFilterName() {
    return this.$t('downloadcenter.search.filter.category');
  },
  i18nLanguagesFilterName() {
    return this.$t('downloadcenter.search.filter.language');
  },
  i18nFileSizeFilterName() {
    return this.$t('downloadcenter.search.filter.filesize');
  },
};

export default {
  components: {
    FiltersListItem,
  },
  data() {
    return {
      selectedFilter: null,
    };
  },
  computed: {
    ...mapGetters('interactionsModule', ['isFiltersModalActive']),
    ...mapGetters('searchModule', ['queryLanguage', 'queryCategory', 'queryFileSize']),
    ...i18nComputedProperties,
    categoryTagsArray() {
      return this.createFilterKeysFromStoreData('documents', this.queryCategory);
    },
    languageTagsArray() {
      return this.createFilterKeysFromStoreData('languages', this.queryLanguage, true);
    },
    fileSizeTagsArray() {
      return this.createFilterKeysFromStoreData('filesize', this.queryFileSize);
    },
    hasFilters() {
      return this.fileSizeTagsArray?.length || this.languageTagsArray?.length || this.categoryTagsArray?.length;
    },
    languageTranslationKeys() {
      const mappedLanguages = config.availableLanguages.map((languageKey) => {
        return {
          key: convertCountryCodeToLanguageCode(languageKey),
          value: this.$t(`downloadcenter.search.languages.${languageKey}`),
        };
      });
      return mappedLanguages;
    },
    categoryTranslationKeys() {
      return SUPPORTED_SEARCH_CATEGORIES.map((item) => {
        return {
          key: item,
          value: this.$t(`downloadcenter.search.documents.${item.toLowerCase()}`),
        };
      });
    },
    fileSizeTranslationKeys() {
      return SUPPORTED_FILE_SIZES.map((fileSize) => {
        return {
          key: fileSize.key,
          value: this.$t(`downloadcenter.search.filesize.${fileSize.key}`),
        };
      });
    },
  },
  methods: {
    ...mapActions('interactionsModule', ['setIsFiltersModalActive']),
    ...mapActions('searchModule', ['setCurrentPage', 'setQueryCategory', 'setQueryLanguage', 'setQueryFileSize', 'clearSearchFilters']),
    handleModalClose() {
      this.setIsFiltersModalActive(false);
    },
    removeAllFilters() {
      this.clearSearchFilters();
    },
    handleFilterSelection(filterName) {
      this.selectedFilter = filterName;
    },
    resetSelectedFilter() {
      this.selectedFilter = null;
    },
    applyFilters() {
      this.setCurrentPage(1);
      this.handleModalClose();
    },
    createFilterKeysFromStoreData(filterName, storeData, convertKey) {
      return storeData.map((item) => {
        let i18nTagName = item;
        if (convertKey && filterName === 'languages') i18nTagName = convertLanguageCodeToCountryCode(item);
        return this.$t(`downloadcenter.search.${filterName}.${i18nTagName.toLowerCase()}`);
      });
    },
  },
};
