import { mapActions } from 'vuex';
import { router } from '@/router';

export default {
  props: {
    categoryKey: {
      type: String,
      default: 'certificate',
    },
    categoryId: {
      type: String,
    },
  },
  computed: {
    i18nCategoryName() {
      return this.$t(`downloadcenter.welcome.${this.categoryKey}`, '...');
    },
  },
  methods: {
    ...mapActions('searchModule', ['setCurrentPage', 'setQueryCategory']),
    handleNavigation() {
      router.push('/search');
      this.setQueryCategory([this.categoryId]);
    },
  },
};