var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: "modalPortal" } }, [
    _c("div", { staticClass: "cmsCookieNotification" }, [
      _c("div", { staticClass: "cmsCookieNotification__overlayBackground" }),
      _vm._v(" "),
      _c("div", { staticClass: "cmsCookieNotification__container" }, [
        _c("div", { staticClass: "cmsCookieNotification__body" }, [
          _c("div", { staticClass: "cmsCookieNotification__content" }, [
            _c("div", { staticClass: "cmsCookieNotification__line" }, [
              _vm._v(
                '\n            Conrad Electronic SE benötigt für einzelne Datennutzungen Ihre\n            Einwilligung, um die Funktion der Website zu gewährleisten und\n            Ihnen unter anderem Informationen zu Ihren Interessen anzuzeigen.\n            Mit Klick auf "Zustimmen" geben Sie Ihre Einwilligung dazu.\n            Ausführliche Informationen erhalten Sie in unserer\n            Datenschutzerklärung.\n          '
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cmsCookieNotification__line" }, [
              _vm._v(
                "\n            Sie haben jederzeit die Möglichkeit Ihre Zustimmung in der\n            Datenschutzerklärung zurück zu nehmen.\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cmsCookieNotification__link" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://www.conrad.de/de/ueber-conrad/rechtliches/datenschutz.html",
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    "\n              Zur Datenschutzerklärung\n            "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cmsCookieNotification__buttonsWrapper" }, [
            _c(
              "div",
              {
                staticClass:
                  "cmsCookieNotification__button cmsCookieNotification__button--reject",
                on: { click: _vm.handleClickReject }
              },
              [
                _c(
                  "span",
                  { staticClass: "cmsCookieNotification__button__label" },
                  [_vm._v("\n              Ablehnen\n            ")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "cmsCookieNotification__button cmsCookieNotification__button--accept",
                on: { click: _vm.handleClickAccept }
              },
              [
                _c(
                  "span",
                  { staticClass: "cmsCookieNotification__button__label" },
                  [_vm._v("\n              Zustimmen\n            ")]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }