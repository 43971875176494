import { mapActions } from 'vuex';
export default {
  props: {
    tagName: {
      type: String,
      default: 'Default',
    },
    activeTag: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions('searchModule', ['setCurrentPage']),
    clickOnCloseTag() {
      this.$emit('remove-tag');
      this.setCurrentPage(1);
    },
  },
};
