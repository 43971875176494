import { mapActions } from 'vuex';
import { acceptCookiesGlobally, rejectCookiesGlobally, setCookieNotificationClosed, setCookieRejectionTimestamp } from '@/utils/cookies';

export default {
  methods: {
    ...mapActions('interactionsModule', ['setIsCookieNotificationActive']),
    hideCookieNotification() {
      this.setIsCookieNotificationActive(false);
    },
    handleClickAccept() {
      acceptCookiesGlobally();
      this.hideCookieNotification();
      setCookieNotificationClosed();
    },
    handleClickReject() {
      this.hideCookieNotification();
      setCookieNotificationClosed();
      setCookieRejectionTimestamp(Date.now());
      rejectCookiesGlobally();
    },
  },
};