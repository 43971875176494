import { mapGetters, mapActions } from 'vuex';
import CustomDropdown from '@/components/customDropdown/customDropdown.vue';
import SearchInput from '@/components/headerSection/searchInput/searchInput.vue';
import { config } from '@/config/config';
import { SENIOR_DOMAIN_URLS, LANGUAGE_NAMES_SET } from '@/utils/constants';

const i18nComputedProperties = {
  i18nToOnlineShop() {
    return this.$t('downloadcenter.header.toonlineshop', 'zum Onlineshop');
  },
  i18nSearchPanelText() {
    return this.$t('downloadcenter.header.searchpaneltext', 'Für welches Produkt benötigen Sie Hilfe?');
  },
};

export default {
  components: {
    CustomDropdown,
    SearchInput,
  },
  props: {
    type: {
      type: String,
      default: 'search',
    },
  },
  computed: {
    ...i18nComputedProperties,
    ...mapGetters('i18nModule', ['language']),
    ...mapGetters('viewportModule', ['isLarge', 'isMedium', 'isSmall', 'isXSmall']),
    shouldShowUpperSearchInput() {
      return !this.isHomePageHeader && (this.isLarge || this.isMedium);
    },
    shouldShowUpperSelect() {
      return this.isLarge || (this.isMedium && this.isHomePageHeader);
    },
    shouldShowBottomSearchInput() {
      return !this.isHomePageHeader && (this.isXSmall || this.isSmall);
    },
    shouldShowBottomSelect() {
      return this.isSmall;
    },
    availableLanguages() {
      return config.availableLanguages;
    },
    languageNames() {
      return LANGUAGE_NAMES_SET;
    },
    selectedLanguage() {
      return this.language.toUpperCase();
    },
    isHomePageHeader() {
      return this.type === 'home';
    },
    shouldShowToOnlineShopLink() {
      return this.isHomePageHeader && !this.isSmall && !this.isXSmall;
    },
    toOnlineShopUrl() {
      return SENIOR_DOMAIN_URLS[this.language] || SENIOR_DOMAIN_URLS['en'];
    },
  },
  methods: {
    ...mapActions('i18nModule', ['changeI18n']),
    handleLanguageSelection(language) {
      this.changeI18n(language);
    },
  },
};