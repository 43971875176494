import Vue from 'vue';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import { i18nModule } from './modules/i18nModule';
import { searchModule } from './modules/searchModule';
import { viewportModule } from './modules/viewportModule';
import { interactionsModule } from './modules/interactionsModule';
import { config } from '@/config/config';

const store = new Vuex.Store({
  modules: {
    i18nModule,
    searchModule,
    viewportModule,
    interactionsModule,
  },
  strict: false,
}); 

const i18nConfig = { 
  warnings: config.i18nWarnings,
};

Vue.use(vuexI18n.plugin, store, i18nConfig);

export { store };