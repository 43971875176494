import HeaderSection from '@/components/headerSection/headerSection.vue';
import NotFoundSection from '@/components/notFoundSection/notFoundSection.vue';
import FooterSection from '@/components/footerSection/footerSection.vue';

export default {
  components: {
    HeaderSection,
    NotFoundSection,
    FooterSection,
  },
};