import { mapActions } from 'vuex';

export default {
  props: {
    optionsList: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
    },
    filterName: {
      type: String,
      default: 'Filter',
    },
    getterProp: {
      type: [Array, String],
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      isSelectOpened: false,
    };
  },
  computed: {
    selectArrowIcon() {
      return this.isSelectOpened ? 'arrow-up' : 'arrow-down';
    },
    vModelFromProps: {
      get() {
        return this.getterProp;
      },
      set(value) {
        this.$emit('setter-prop', value);
        this.setCurrentPage(1);
      },
    },
  },
  methods: {
    ...mapActions('searchModule', ['setCurrentPage']),
    isCheckboxSelected(key) {
      return !!this.getterProp.includes(key);
    },
    handleOpenDropdown() {
      this.isSelectOpened = !this.isSelectOpened;
    },
  },
};
