import { config } from '@/config/config';

const isLanguageAvailable = (payload) => config.availableLanguages.includes(payload);
const getNavigatorLanguage = () => navigator.language || navigator.userLanguage; 
const sanitizeLanguageFromLocale = (payload) => payload.split('-')[0]; 

const getPreferredLanguage = () => {
  const storedLanguage = localStorage.getItem('language');
  const isStoredLanguageAvailable = isLanguageAvailable(storedLanguage);
  if (storedLanguage && isStoredLanguageAvailable) { return storedLanguage; }
  const navigatorLanguage = getNavigatorLanguage();
  const sanitizedNavigatorLanguage = sanitizeLanguageFromLocale(navigatorLanguage);
  const isNavigatorLanguageAvailable = isLanguageAvailable(sanitizedNavigatorLanguage);
  return  isNavigatorLanguageAvailable ? sanitizedNavigatorLanguage : config.defaultLanguage;
};

const setPreferredLanguage = (language) => {
  localStorage.setItem('language', language);
};

const LANGUAGE_TO_COUNTRY_RELATION = {
  cs: 'cz', // Czech: Czechia
  zh: 'cn', // Chinese: China
  da: 'dk', // Danish: Denmark
  el: 'gr', // Greek: Greece
  sv: 'se', // Swedish: Sweden
};

/**
 * Converts ISO 639-1 codes to ISO 3166-1 alpha-2
 *
 * @param languageCode ISO 639-1 code
 * @returns {String} ISO 3166-1 alpha-2 code
 */
const convertLanguageCodeToCountryCode = (languageCode) => {
  const lowerCaseLanguageCode = languageCode.toLowerCase();
  const countryCode = LANGUAGE_TO_COUNTRY_RELATION[lowerCaseLanguageCode];
  return countryCode || lowerCaseLanguageCode;
};

/**
 * Converts ISO 3166-1 alpha-2 codes to ISO 639-1
 *
 * @param countryCode ISO 3166-1 alpha-2
 * @returns {String} ISO 639-1
 */
const convertCountryCodeToLanguageCode = (countryCode) => {
  const lowerCaseCountryCode = countryCode.toLowerCase();
  const languageCode = Object.keys(LANGUAGE_TO_COUNTRY_RELATION)
    .find(languageKey => LANGUAGE_TO_COUNTRY_RELATION[languageKey] === lowerCaseCountryCode);
  return languageCode || countryCode;
};

export {
  getPreferredLanguage,
  setPreferredLanguage,
  convertLanguageCodeToCountryCode,
  convertCountryCodeToLanguageCode,
};