import { mapActions } from 'vuex';

const i18nComputedProperties = {
  i18nFilterHeaderReset() {
    return this.$t('downloadcenter.search.filtersmodal.resetfilter');
  },
};

export default {
  props: {
    filterName: {
      type: String,
      default: 'Filter',
    },
    filtersArray: {
      type: Array,
    },
    optionsList: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
    },
    getterProp: {
      type: [Array, String],
    },
    isFilterSelected: {
      type: Boolean,
    },
  },
  computed: {
    ...i18nComputedProperties,
    vModelFromProps: {
      get() {
        return this.getterProp;
      },
      set(value) {
        this.$emit('setter-prop', value);
      },
    },
    selectedFiltersList() {
      return this.filtersArray?.join(', ');
    },
  },
  methods: {
    ...mapActions('searchModule', ['setCurrentPage']),
    isCheckboxSelected(key) {
      return !!this.getterProp.includes(key);
    },
    resetFilter() {
      this.$emit('setter-prop', []);
    },
  },
};
