const path = require('path');

const buildType = process.env.BUILD_TYPE;
const rootDir = path.resolve(__dirname, '../../');
const appRootFolder = `${rootDir}/src`;
const outputPath = `${rootDir}/dist/www`;
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET',
};

const version = process.env.VERSION;

module.exports = { 
  buildType,
  rootDir,
  appRootFolder,
  outputPath,
  headers,
  version,
};