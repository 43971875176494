const SENIOR_DOMAIN_URLS = {
  'en': 'https://www.conrad.com/',
  'de': 'https://www.conrad.de/',
  'nl': 'https://www.conrad.nl/',
  'be': 'https://www.conrad.be/',
  'pl': 'https://www.conrad.pl/',
  'fr': 'https://www.conrad.fr/',
  'it': 'https://www.conrad.it/',
  'cz': 'https://www.conrad.cz/',
  'se': 'https://www.conrad.se/',
  'dk': 'https://www.conradelektronik.dk/',
};

const LANGUAGE_NAMES_SET = {
  'bg': 'български',
  'cn':	'中文',
  'cz':	'Čeština',
  'de':	'Deutsch',
  'dk':	'Dansk',
  'en':	'English',
  'es':	'Español',
  'fi':	'suomi',
  'fr':	'Français',
  'gr':	'Ελληνικά',
  'hr':	'Hrvatski',
  'hu':	'Magyar',
  'it':	'Italiano',
  'nl':	'Nederlands',
  'no':	'Norsk',
  'pl':	'Polski',
  'pt':	'Português',
  'ro':	'Română',
  'ru':	'Русский',
  'se':	'svenska',
  'sk':	'Slovenčina',
  'sl':	'Slovensko',
  'tr':	'Türkçe',
};

const BENEFITS_LIST = ['security', 'privacy', 'encryption', 'visamastercard'];

const DOWNLOAD_TERMS_URLS = {
  'en': 'https://www.conrad.com/about-us/about-us/infocentre/terms-conditions',
  'de': 'https://www.conrad.de/de/ueber-conrad/rechtliches.html',
  'cz': 'https://www.conrad.cz/o-nas/o-nas/o-nas',
  'hr': 'https://www.conrad.hr/o-nas/informacije/o-tvrtki-conrad',
  'hu': 'https://www.conrad.hu/rolunk/altalanos/sourcing-platform',
  'pl': 'https://www.conrad.pl/faq/regulamin-i-informacje-prawne/informacje-dla-konsumentow',
  'sk': 'https://www.conrad.sk/o-nas/o-nas/o-nas',
  'sl': 'https://www.conrad.si/o-nas/informacije/o-podjetju-conrad',  
};

const LEGAL_NOTICE_URLS = {
  'en': 'https://www.conrad.com/about-us/about-us/infocentre/imprint',
  'de': 'https://www.conrad.de/de/ueber-conrad/rechtliches/impressum.html',
  'cz': 'https://www.conrad.cz/o-nas/o-nas/conrad-sourcing-platform',
  'hr': 'https://www.conrad.hr/o-nas/informacije/conrad-sourcing-platform',
  'hu': 'https://www.conrad.hu/gyik/jogi-tajekoztatok/aszf',
  'pl': 'https://www.conrad.pl/o-nas/about-conrad/conrad-sourcing-platform',
  'sk': 'https://www.conrad.sk/o-nas/o-nas/conrad-sourcing-platform',
  'sl': 'https://www.conrad.si/o-nas/informacije/conrad-sourcing-platform',  
};

const PRIVACY_POLICY_URLS = {
  'en': 'https://www.conrad.com/about-us/about-us/infocentre/privacy-policy',
  'de': 'https://www.conrad.de/de/ueber-conrad/rechtliches/datenschutz.html',
  'cz': 'https://www.conrad.cz/faq/vseobecne-obchodni-podminky/vop-rozcestnik',
  'hr': 'https://www.conrad.hr/faq/zastita-podataka/osobni-podaci-i-kako-ih-stitimo',
  'hu': 'https://www.conrad.hu/gyik/jogi-tajekoztatok/adatkezelesi-tajekoztato',
  'pl': 'https://www.conrad.pl/faq/regulamin-i-informacje-prawne/polityka-prywatnosci-nowa-copy',
  'sk': 'https://www.conrad.sk/faq/vseobecne-obchodne-podmienky/vop-rozcestnik',
  'sl': 'https://www.conrad.si/faq/zascita-podatkov/varovanje-in-dostopnost-podatkov',
};

const CONRAD_COMMUNITY_URL = 'https://community.conrad.com';

const CATEGORIES_LIST = [
  {
    key: 'certificate', 
    value: 'gscertificate',
  },
  {
    key: 'circuitdiagram', 
    value: 'circuitdiagram',
  },
  {
    key: 'sparepartlist', 
    value: 'sparepartList',
  },
  {
    key: 'datasheet', 
    value: 'datasheet',
  },
  {
    key: 'manual', 
    value: 'manual',
  },
  {
    key: 'software', 
    value: 'download',
  },
];

const SUPPORTED_RESULTS_PER_PAGE = [30, 45, 60];

const SUPPORTED_FILE_SIZES = [
  {
    key: 'small', 
    fileSizeMin: 0,
    fileSizeMax: 1000000,
  }, 
  {
    key: 'medium', 
    fileSizeMin: 1000000,
    fileSizeMax: 5000000,
  }, 
  {
    key: 'large',
    fileSizeMin: 5000000,
    fileSizeMax: 50000000,
  },
];

const SUPPORTED_SEARCH_CATEGORIES = [
  'Manual',
  'Datasheet',
  'Information',
  'CircuitDiagram',
  'ConformityCertificate',
  'RadioCertificate',
  'gsCertificate',
  'eNumCertificate',
  'SecurityDatasheet',
  'WarrantyTerms',
  'SparePartList',
  'REACHInformation',
  'Download',
  'ManualMP',
  'DatasheetMP',
  'CertificateMP',
  'SecurityDatasheetMP',
];

const IS_DEVELOPMENT = process.env.BUILD_TYPE === 'development';
const IS_PROD_DOMAIN = window.location.hostname === 'produktinfo.conrad';

const GTM_ID = 'GTM-5KBLZWD';

export {
  SENIOR_DOMAIN_URLS,
  LANGUAGE_NAMES_SET,
  BENEFITS_LIST,
  DOWNLOAD_TERMS_URLS,
  LEGAL_NOTICE_URLS,
  PRIVACY_POLICY_URLS,
  CONRAD_COMMUNITY_URL,
  CATEGORIES_LIST,
  SUPPORTED_FILE_SIZES,
  SUPPORTED_SEARCH_CATEGORIES,
  SUPPORTED_RESULTS_PER_PAGE,
  IS_DEVELOPMENT,
  IS_PROD_DOMAIN,
  GTM_ID,
};