var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer__benefitsPanel" }, [
      _c("div", { staticClass: "footer__benefitsPanelWrapper" }, [
        _c(
          "ul",
          { staticClass: "footer__benefitsList" },
          [
            _c("li", { staticClass: "footer__benefitsListItem" }, [
              _vm._v(" " + _vm._s() + " ")
            ]),
            _vm._v(" "),
            _vm._l(_vm.benefitsList, function(benefit, index) {
              return _c(
                "li",
                { key: index, staticClass: "footer__benefitsListItem" },
                [
                  _c("icon", {
                    staticClass: "footer__benefitsListItemIcon",
                    attrs: { name: "hook", scale: "70" }
                  }),
                  _vm._v("\n          " + _vm._s(benefit) + "\n        ")
                ],
                1
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer__linksPanel" }, [
      _c("div", { staticClass: "footer__linksPanelWrapper" }, [
        _c("div", { staticClass: "footer__linksContainer" }, [
          _c(
            "a",
            {
              staticClass: "footer__link",
              attrs: { href: _vm.downloadTermsUrl, target: "_blank" }
            },
            [
              _vm._v(
                "\n          " + _vm._s(_vm.i18nDownloadTerms) + "\n        "
              )
            ]
          ),
          _vm._v("\n        |\n        "),
          _c(
            "a",
            {
              staticClass: "footer__link footer__link--bordered",
              attrs: { href: _vm.legalNoticeUrl, target: "_blank" }
            },
            [
              _vm._v(
                "\n          " + _vm._s(_vm.i18nLegalNotice) + "\n        "
              )
            ]
          ),
          _vm._v("\n        |\n        "),
          _c(
            "a",
            {
              staticClass: "footer__link footer__link--bordered",
              attrs: { href: _vm.privacyPolicyUrl, target: "_blank" }
            },
            [
              _vm._v(
                "\n          " + _vm._s(_vm.i18nPrivacyPolicy) + "\n        "
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }