import { mapGetters } from 'vuex';

const i18nComputedProperties = {
  i18n404Additional() {
    return this.$t('downloadcenter.notfound.404additional');
  },
  i18nTextSectionFirstTitle() {
    return this.$t('downloadcenter.notfound.textsection.firsttitle');
  },
  i18nTextSectionSecondTitle() {
    return this.$t('downloadcenter.notfound.textsection.secondtitle');
  },
  i18nTextSectionFirstParagraph() {
    return this.$t('downloadcenter.notfound.textsection.firstparagraph');
  },
  i18nTextSectionSecondParagraph() {
    return this.$t('downloadcenter.notfound.textsection.secondparagraph');
  },
};

export default {
  computed: {
    ...mapGetters('viewportModule', ['isLarge']),
    ...i18nComputedProperties,
    shouldDisplayTextSection() {
      return this.isLarge;
    },
  },
};