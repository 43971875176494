var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["paginationItem", { "paginationItem--active": _vm.activePage }],
      on: {
        click: function($event) {
          return _vm.$emit("set-current-page")
        }
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.pageNumber) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }