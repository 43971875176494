var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      class: ["svgIcon", { "svgIcon--spinner": _vm.spin }],
      style: _vm.style,
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.box,
        role: "presentation",
        version: "1.1"
      }
    },
    _vm._l(_vm.icon.paths, function(path, index) {
      return _c("path", {
        key: index,
        attrs: { d: path.d, fill: path.fill, stroke: path.stroke }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }