import middlewareConnector from '@/connector/middlewareConnector.js';
import { SUPPORTED_FILE_SIZES } from '@/utils/constants';

const ILLEGAL_CHARACTER_IDENTIFICATION_REGEX = /[^a-zA-Z0-9а-я-_ äÄöÖüÜáÁàÀéÉèÈß]/g;

const initialState = {
  currentPage: 1,
  amountPages: 0,
  result: [],
  query: '',
  currentSearch: '',
  queryCategory: [],
  queryLanguage: [],
  queryFileSize: [],
  fileSizeMin: null,
  fileSizeMax: null,
  itemsPerPage: 30,
  doctype: '',
  offset: 0,
  foundAssets: 0,
  completeItems: 0,
  searchInProgress: false,
  supportedSearchModes: ['AUTO', 'TEXT', 'ARTICLE_NUMBER_OR_EAN', 'PART_NUMBER'],
  searchMode: 'AUTO',
};

const mutations = {
  CHANGE_RESULT(state, payload) {
    state.result = payload;
  },
  CHANGE_ITEMS_PER_PAGE(state, payload) {
    state.itemsPerPage = payload;
  },
  CHANGE_QUERY(state, payload) {
    state.query = payload;
  },
  SET_CURRENT_SEARCH(state, payload) {
    state.currentSearch = payload;
  },
  CHANGE_QUERY_LANGUAGE(state, payload) {
    state.queryLanguage = payload;
  },
  DELETE_QUERY_LANGUAGE(state, payload) {
    state.queryLanguage.splice(state.queryLanguage.indexOf(payload), 1);
  },
  CHANGE_QUERY_CATEGORY(state, payload) {
    state.queryCategory = payload;
  },
  DELETE_QUERY_CATEGORY(state, payload) {
    state.queryCategory.splice(state.queryCategory.indexOf(payload), 1);
  },
  CHANGE_QUERY_FILE_SIZE(state, payload) {
    state.queryFileSize = payload;
  },
  DELETE_QUERY_FILE_SIZE(state, payload) {
    state.queryFileSize.splice(state.queryFileSize.indexOf(payload), 1);
  },
  CHANGE_FILE_SIZE_MIN(state, payload) {
    state.fileSizeMin = payload;
  },
  CHANGE_FILE_SIZE_MAX(state, payload) {
    state.fileSizeMax = payload;
  },
  CHANGE_DOCTYPE(state, payload) {
    state.doctype = payload;
  },
  CHANGE_OFFSET(state, payload) {
    state.offset = payload;
  },
  CHANGE_FOUND_ASSETS(state, payload) {
    state.foundAssets = payload;
  },
  CHANGE_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  CHANGE_AMOUNT_PAGES(state, payload) {
    state.amountPages = payload;
  },
  CHANGE_SEARCH_IN_PROGRESS(state, payload) {
    state.searchInProgress = payload;
  },
  CHANGE_SEARCH_MODE(state, payload) {
    state.searchMode = payload;
  },
  RESET_RESULT_STATE(state) {
    state.result = [];
    state.amountPages = 0;
    state.foundAssets = 0;
  },
};

const actions = {
  setQuery({commit}, query) {
    const cleanQuery = (query || '').replace(ILLEGAL_CHARACTER_IDENTIFICATION_REGEX, '_');
    commit('CHANGE_QUERY', cleanQuery);
  },
  setQueryLanguage({commit}, language) {
    commit('CHANGE_QUERY_LANGUAGE', language);
  },
  deleteQueryLanguage({commit}, language) {
    commit('DELETE_QUERY_LANGUAGE', language);
  },
  setQueryCategory({commit}, category) {
    commit('CHANGE_QUERY_CATEGORY', category);
  },
  deleteQueryCategory({commit}, category) {
    commit('DELETE_QUERY_CATEGORY', category);
  },
  setQueryFileSize({commit, dispatch}, fileSize) {
    commit('CHANGE_QUERY_FILE_SIZE', fileSize);
    dispatch('updateFileSizeMinMax');
  },
  deleteQueryFileSize({commit, dispatch}, fileSize) {
    commit('DELETE_QUERY_FILE_SIZE', fileSize);
    dispatch('updateFileSizeMinMax');
  },
  setFileSizeMin({commit}, fileSizeMin) {
    commit('CHANGE_FILE_SIZE_MIN', fileSizeMin);
  },
  setFileSizeMax({commit}, fileSizeMax) {
    commit('CHANGE_FILE_SIZE_MAX', fileSizeMax);
  },
  updateFileSizeMinMax({ dispatch, getters }) {
    const queryFileSize = getters.queryFileSize;

    if (!queryFileSize?.length) {
      dispatch('setFileSizeMin', null);
      dispatch('setFileSizeMax', null);
      return;
    }
    const selectedDataFromStore = SUPPORTED_FILE_SIZES.filter(item => {
      return queryFileSize.includes(item.key);
    });
     
    const fileSizeMin = Math.min(...selectedDataFromStore.map(item => item.fileSizeMin));
    dispatch('setFileSizeMin', fileSizeMin);
    const fileSizeMax = Math.max(...selectedDataFromStore.map(item => item.fileSizeMax));
    dispatch('setFileSizeMax', fileSizeMax);
  },
  clearSearchFilters({ dispatch }) {
    dispatch('setQueryCategory', []);
    dispatch('setQueryLanguage', []);
    dispatch('setQueryFileSize', []);
  },
  async getList({commit, getters}) {
    const query = getters.query;
    if (query.length < 3) {
      commit('RESET_RESULT_STATE');
    }

    commit('CHANGE_SEARCH_IN_PROGRESS', true);
    commit('SET_CURRENT_SEARCH', query);

    try {
      const response = await middlewareConnector.getAssets(false);
      const responseData = response.data;
      commit('CHANGE_RESULT', responseData.assets);
      commit('CHANGE_FOUND_ASSETS', responseData.totalAssets || 0);
      commit('CHANGE_AMOUNT_PAGES', responseData.numberOfPages || 0);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      commit('CHANGE_SEARCH_IN_PROGRESS', false);
    }
  },
  setItemsPerPage({commit}, itemsPerPage) {
    commit('CHANGE_ITEMS_PER_PAGE', itemsPerPage);
  },
  setDocType({commit}, doctype) {
    commit('CHANGE_DOCTYPE', doctype);
  },
  setOffset({commit, state}, page) {
    let offset = page * state.itemsPerPage - state.itemsPerPage;
    if (offset < 0) {
      offset = 0;
    }
    commit('CHANGE_OFFSET', offset);
  },
  setCurrentPage({commit, dispatch}, currentPage) {
    commit('CHANGE_CURRENT_PAGE', currentPage);
    dispatch('setOffset', currentPage);
    dispatch('getList');
  },
  setSearchMode({commit}, searchMode) {
    commit('CHANGE_SEARCH_MODE', searchMode);
  },
};

const getters = {
  result: ({result}) => result,
  query: ({query}) => query,
  currentSearch: ({currentSearch}) => currentSearch,
  queryLanguage: ({queryLanguage}) => queryLanguage,
  queryFileSize: ({queryFileSize}) => queryFileSize,
  queryCategory: ({queryCategory}) => queryCategory,
  fileSizeMin: ({fileSizeMin}) => fileSizeMin,
  fileSizeMax: ({fileSizeMax}) => fileSizeMax,
  itemsPerPage: ({itemsPerPage}) => itemsPerPage,
  doctype: ({doctype}) => doctype,
  offset: ({offset}) => offset,
  foundAssets: ({foundAssets}) => foundAssets,
  currentPage: ({currentPage}) => currentPage,
  amountPages: ({amountPages}) => amountPages,
  searchInProgress: ({searchInProgress}) => searchInProgress,
  supportedSearchModes: ({supportedSearchModes}) => supportedSearchModes,
  searchMode: ({searchMode}) => searchMode,
};

const searchModule = {
  state: initialState,
  namespaced: true,
  mutations,
  actions,
  getters,
};

export {searchModule};