import Router from 'vue-router';
import home from '@/views/home/home.vue';
import notFound from '@/views/notFound/notFound.vue';
import search from '@/views/search/search.vue';

const notFoundRoute = {
  path: '*',
  name: 'notFound',
  component: notFound,
};

const homeRoute = {
  path: '/',
  name: 'home',
  component: home, 
};

const searchRoute = {
  path: '/search',
  name: 'search',
  component: search, 
};

const routes = [
  notFoundRoute,
  homeRoute,
  searchRoute,
];

const routerOptions = {
  routes,
  mode: 'history',
};

const router = new Router(routerOptions);

export { router };