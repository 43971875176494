var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchResultListNothingFound" }, [
    !_vm.shouldShowNoCategorySearchPhrase
      ? _c("div", [_vm._v("\n    " + _vm._s(_vm.i18nNoResultsPhrase) + "\n  ")])
      : _vm._e(),
    _vm._v(" "),
    _vm.shouldShowNoCategorySearchPhrase
      ? _c("div", [
          _vm._v("\n    " + _vm._s(_vm.i18nNoCategorySearchPhrase) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }