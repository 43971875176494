import CategoryItem from './categoryItem/categoryItem.vue';
import { CATEGORIES_LIST } from '@/utils/constants';

export default {
  components: {
    CategoryItem,
  },
  computed: {
    categoriesSectionTitleText() {
      return this.$t('downloadcenter.categories.popular', 'Beliebte Kategorien');
    },
    categoriesList() {
      return CATEGORIES_LIST;
    },
  },
};