const bpXSmall = 414; //Duplicates bp values in px from _variables.scss
const bpSmall = 720;
const bpMedium = 1024;

const initialState = {
  windowWidth: null,
};

const mutations = {
  SET_WINDOW_WIDTH(state, payload) {
    state.windowWidth = payload;
  },
};

const actions = {
  setWindowWidth({commit}, windowWidth) {
    commit('SET_WINDOW_WIDTH', windowWidth);
  },
  toggleIsBodyScrollable({}, payload) {
    document.body.classList.toggle('no-scroll', payload);
  },
};

const getters = {
  isXSmall: ({ windowWidth }) => windowWidth < bpXSmall,
  isSmall: ({ windowWidth }) => windowWidth >= bpXSmall && windowWidth < bpSmall,
  isMedium: ({ windowWidth }) => windowWidth >= bpSmall && windowWidth < bpMedium,
  isLarge: ({ windowWidth }) => windowWidth >= bpMedium,
};

const viewportModule = {
  state: initialState,
  namespaced: true,
  mutations,
  actions,
  getters,
};

export { viewportModule };