var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("headerSection", { attrs: { type: "search" } }),
      _vm._v(" "),
      _c("searchResult"),
      _vm._v(" "),
      _c("footerSection")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }