var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resultsPerPage" }, [
    _c("span", { staticClass: "resultsPerPage__allResults" }, [
      _vm._v("\n    " + _vm._s(_vm.i18nAllResultsPerPage) + "\n  ")
    ]),
    _vm._v(" "),
    !_vm.isXSmall
      ? _c(
          "div",
          { staticClass: "resultsPerPage__resultsSelectWrapper" },
          [
            _c("customDropdown", {
              staticClass: "resultsPerPage__resultsSelect",
              attrs: {
                "options-list": _vm.supportedResultsPerPage,
                "select-title": "" + _vm.itemsPerPage,
                "icon-scale": 80,
                disabled: _vm.searchInProgress
              },
              on: { "handle-option-selection": _vm.handleItemsPerPageChange }
            }),
            _vm._v("\n    " + _vm._s(_vm.i18nResultsSelect) + "\n  ")
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }