var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categoryItem", on: { click: _vm.handleNavigation } },
    [
      _c("icon", {
        staticClass: "categoryItem__icon",
        attrs: { name: _vm.categoryKey, scale: "280" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "categoryItem__titleWrapper" }, [
        _c("div", { staticClass: "categoryItem__title" }, [
          _vm._v("\n      " + _vm._s(_vm.i18nCategoryName) + "\n    ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }