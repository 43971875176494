var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "header__topPanel" }, [
      _c("div", { staticClass: "header__topPanelWrapper" }, [
        _c("div", { staticClass: "header__logoTitleWrapper" }, [
          _c("div", [
            _c(
              "a",
              { attrs: { href: "/" } },
              [
                _c("icon", {
                  staticClass: "header__conradLogoImage",
                  attrs: { name: "conrad-logo", scale: "150" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "header__languageSwitcher" },
          [
            _c("customDropdown", {
              attrs: {
                "options-list": _vm.availableLanguages,
                "option-translations": _vm.languageNames,
                "select-title": _vm.selectedLanguage,
                alignment: "right"
              },
              on: { "handle-option-selection": _vm.handleLanguageSelection }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.shouldShowToOnlineShopLink
          ? _c("div", { staticClass: "header__toOnlineShopWrapper" }, [
              _c(
                "a",
                {
                  staticClass: "header__toOnlineShopLink",
                  attrs: { href: _vm.toOnlineShopUrl }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.i18nToOnlineShop) + "\n        "
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.shouldShowUpperSearchInput
          ? _c(
              "div",
              { staticClass: "header__upperSearchInput" },
              [
                _c("searchInput", {
                  attrs: { "should-show-select": _vm.shouldShowUpperSelect }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _vm.shouldShowBottomSearchInput
      ? _c(
          "div",
          { staticClass: "header__bottomSearchInputWrapper" },
          [
            _c("searchInput", {
              attrs: { "should-show-select": _vm.shouldShowBottomSelect }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isHomePageHeader
      ? _c("div", { staticClass: "header__searchPanel" }, [
          _c(
            "div",
            { staticClass: "header__searchPanelWrapper" },
            [
              _c("h2", { staticClass: "header__searchPanelTitle" }, [
                _vm._v(_vm._s(_vm.i18nSearchPanelText))
              ]),
              _vm._v(" "),
              _c("searchInput", {
                attrs: { "should-show-select": !_vm.isXSmall }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__title" }, [
      _c("div", { staticClass: "header__title--bordered" }),
      _vm._v(" "),
      _c("span", { staticClass: "header__title--conrad-blue" }, [
        _vm._v("Download")
      ]),
      _vm._v(" Center\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }