var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "categoriesSection" }, [
    _c("div", { staticClass: "categoriesSection__wrapper" }, [
      _c("div", { staticClass: "categoriesSection__titleWrapper" }, [
        _c("h3", { staticClass: "categoriesSection__title" }, [
          _vm._v(_vm._s(_vm.categoriesSectionTitleText))
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "categoriesSection__titleLine" })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "categoriesSection__categoriesList" },
        _vm._l(_vm.categoriesList, function(category, index) {
          return _c("categoryItem", {
            key: "category" + index,
            staticClass: "categoriesSection__categoryItem",
            attrs: {
              "category-key": category.key,
              "category-id": category.value
            }
          })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }