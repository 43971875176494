const initialState = {
  isFiltersModalActive: false,
  isCookieNotificationModalActive: true,
};

const mutations = {
  SET_IS_FILTERS_MODAL_ACTIVE(state, payload) {
    state.isFiltersModalActive = payload;
  },
  SET_IS_COOKIE_NOTIFICATION_MODAL_ACTIVE(state, payload) {
    state.isCookieNotificationModalActive = payload;
  },
};

const actions = {
  async setIsFiltersModalActive({ commit, dispatch }, isFiltersModalActive) {
    commit('SET_IS_FILTERS_MODAL_ACTIVE', isFiltersModalActive);
    dispatch('viewportModule/toggleIsBodyScrollable', isFiltersModalActive, { root: true });
  },
  async setIsCookieNotificationActive({ commit }, isCookieNotificationModalActive) {
    commit('SET_IS_COOKIE_NOTIFICATION_MODAL_ACTIVE', isCookieNotificationModalActive);
  },
};

const getters = {
  isFiltersModalActive: ({ isFiltersModalActive }) => isFiltersModalActive,
  isCookieNotificationModalActive: ({ isCookieNotificationModalActive }) => isCookieNotificationModalActive,
};

const interactionsModule = {
  state: initialState,
  namespaced: true,
  mutations,
  actions,
  getters,
};

export { interactionsModule };