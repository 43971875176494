var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "customDropdown__selectContainer",
      attrs: { tabindex: "1" },
      on: {
        blur: function($event) {
          _vm.isSelectOpened = false
        }
      }
    },
    [
      _c(
        "div",
        {
          class: [
            "customDropdown__select",
            { "customDropdown__select--disabled": _vm.disabled },
            { "customDropdown__select--opened": _vm.isSelectOpened }
          ],
          on: { click: _vm.handleOpenDropdown }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.selectTitle) + "\n    "),
          _c("icon", {
            class: [
              "customDropdown__arrowIcon",
              { "customDropdown__arrowIcon--disabled": _vm.disabled }
            ],
            attrs: { name: _vm.selectArrowIcon, scale: _vm.iconScale }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "customDropdown__optionsContainer",
          class: [
            "customDropdown__optionsContainer",
            {
              "customDropdown__optionsContainer--rightAligned":
                _vm.isRightAligned
            },
            { "customDropdown__optionsContainer--hidden": !_vm.isSelectOpened }
          ]
        },
        _vm._l(_vm.optionsList, function(option) {
          return _c(
            "div",
            {
              key: option,
              staticClass: "customDropdown__option",
              on: {
                click: function($event) {
                  return _vm.onOptionSelection(option)
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.getFormattedOption(option)) + "\n    "
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }