var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchInput__wrapper" }, [
    _vm.shouldShowSelect
      ? _c(
          "div",
          {
            staticClass: "searchInput__selectContainer",
            attrs: { tabindex: "0" },
            on: {
              blur: function($event) {
                _vm.isSelectOpened = false
              }
            }
          },
          [
            _c(
              "div",
              {
                class: [
                  "searchInput__select",
                  { "searchInput__select--opened": _vm.isSelectOpened }
                ],
                on: {
                  click: function($event) {
                    _vm.isSelectOpened = !_vm.isSelectOpened
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.selectedOptionTranslation) +
                    "\n      "
                ),
                _c("icon", {
                  staticClass: "searchInput__arrowIcon",
                  attrs: { name: _vm.selectArrowIcon, scale: "90" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "searchInput__optionsContainer",
                class: {
                  "searchInput__optionsContainer--hidden": !_vm.isSelectOpened
                }
              },
              _vm._l(_vm.searchModeTranslationKeys, function(
                translationKey,
                index
              ) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "searchInput__option",
                    on: {
                      click: function($event) {
                        return _vm.handleOptionSelection(translationKey.key)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(translationKey.lang) + "\n      "
                    )
                  ]
                )
              }),
              0
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          "searchInput__inputContainer",
          { "searchInput__inputContainer--leftBordered": _vm.shouldShowSelect }
        ]
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchQuery,
              expression: "searchQuery"
            }
          ],
          ref: "search",
          class: [
            "searchInput__input",
            { "searchInput__input--disabled": _vm.searchInProgress }
          ],
          attrs: {
            placeholder: _vm.searchPlaceholder,
            disabled: _vm.searchInProgress,
            type: "text"
          },
          domProps: { value: _vm.searchQuery },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.handleNavigation($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchQuery = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "searchInput__searchButton",
            on: { click: _vm.handleNavigation }
          },
          [
            _c("icon", {
              class: [
                "searchInput__searchIcon",
                { "searchInput__searchIcon--disabled": _vm.searchInProgress }
              ],
              attrs: { name: "search", scale: "84" }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }