export default {
  data() {
    return {
      isSelectOpened: false,
    };
  },
  props: {
    optionsList: {
      type: Array,
      required: true,
    },
    optionTranslations: Object,
    selectTitle: {
      type: String,
      required: true,
    },
    iconScale: {
      type: Number,
      default: 70,
    },
    disabled: Boolean,
    alignment: String,
  },
  computed: {
    selectArrowIcon() {
      return this.isSelectOpened ? 'arrow-up' : 'arrow-down';
    },
    isRightAligned() {
      return this.alignment === "right";
    },
  },
  methods: {
    onOptionSelection(option) {
      this.$emit('handle-option-selection', option);
      this.isSelectOpened = false;
    },
    getFormattedOption(option) {
      if (this.optionTranslations) return this.optionTranslations[option];
      return option;
    },
    handleOpenDropdown() {
      if (this.disabled) return;
      this.isSelectOpened = !this.isSelectOpened;
    },
  },
};