import { mapGetters, mapActions } from 'vuex';
import { shouldShowCookieNotification, getCookiesGloballyAccepted } from '@/utils/cookies';
import { IS_PROD_DOMAIN, GTM_ID } from '@/utils/constants';
import CookieNotificationModal from '@/components/cookieNotificationModal/cookieNotificationModal.vue';

export default {
  components: {
    CookieNotificationModal,
  },

  computed: {
    ...mapGetters('interactionsModule', ['isCookieNotificationModalActive']),
    shouldShowCookieNotificationModal() {
      return shouldShowCookieNotification() && this.isCookieNotificationModalActive;
    },
  },

  methods: {
    ...mapActions('i18nModule', ['initI18n']),
    ...mapActions('viewportModule', ['setWindowWidth']),
    onResize() {
      this.$nextTick(() => {
        this.setWindowWidth(window.innerWidth);
      });
    },
    initGTM(w = window, d = document, s = 'script', l = 'dataLayer', i = GTM_ID) {
      if (!IS_PROD_DOMAIN) return;
      
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    },
  },

  mounted() {
    this.initI18n();
    this.onResize();

    if (getCookiesGloballyAccepted()) this.initGTM();
    
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  watch: {
    isCookieNotificationModalActive(value) {
      if (!value && getCookiesGloballyAccepted()) this.initGTM();
    },
  },
};