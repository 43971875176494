var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: "modalPortal" } }, [
    _c(
      "div",
      {
        class: [
          "filtersModal",
          { "filtersModal--active": _vm.isFiltersModalActive }
        ],
        attrs: { tabindex: "0" },
        on: { blur: _vm.handleModalClose }
      },
      [
        _c("div", { staticClass: "filtersModal__header" }, [
          !_vm.selectedFilter
            ? _c("span", { staticClass: "filtersModal__title" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.i18nFiltersModalTitle) + "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedFilter
            ? _c(
                "span",
                {
                  staticClass: "filtersModal__back",
                  on: { click: _vm.resetSelectedFilter }
                },
                [
                  _c("icon", {
                    staticClass: "filtersModal__backArrowIcon",
                    attrs: { name: "arrow-down", scale: "100" }
                  }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.i18nFiltersModalBack) + "\n      "
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filtersModal__closeButton",
              on: { click: _vm.handleModalClose }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.i18nFiltersModalClose) + "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        !_vm.selectedFilter && _vm.hasFilters
          ? _c("div", { staticClass: "filtersModal__removeFilters" }, [
              _c(
                "span",
                {
                  staticClass: "filtersModal__removeFiltersText",
                  on: { click: _vm.removeAllFilters }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.i18nRemoveAllFilters) + "\n      "
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filtersModal__filtersList" },
          [
            !_vm.selectedFilter || _vm.selectedFilter === "category"
              ? _c("filtersListItem", {
                  attrs: {
                    "filter-name": _vm.i18nCategoriesFilterName,
                    "filters-array": _vm.categoryTagsArray,
                    "options-list": _vm.categoryTranslationKeys,
                    "getter-prop": _vm.queryCategory,
                    "is-filter-selected": _vm.selectedFilter === "category"
                  },
                  on: {
                    "setter-prop": _vm.setQueryCategory,
                    "handle-filter-selection": function($event) {
                      return _vm.handleFilterSelection("category")
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.selectedFilter || _vm.selectedFilter === "language"
              ? _c("filtersListItem", {
                  attrs: {
                    "filter-name": _vm.i18nLanguagesFilterName,
                    "filters-array": _vm.languageTagsArray,
                    "options-list": _vm.languageTranslationKeys,
                    "getter-prop": _vm.queryLanguage,
                    "is-filter-selected": _vm.selectedFilter === "language"
                  },
                  on: {
                    "setter-prop": _vm.setQueryLanguage,
                    "handle-filter-selection": function($event) {
                      return _vm.handleFilterSelection("language")
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.selectedFilter || _vm.selectedFilter === "filesize"
              ? _c("filtersListItem", {
                  attrs: {
                    "filter-name": _vm.i18nFileSizeFilterName,
                    "filters-array": _vm.fileSizeTagsArray,
                    "options-list": _vm.fileSizeTranslationKeys,
                    "getter-prop": _vm.queryFileSize,
                    "is-filter-selected": _vm.selectedFilter === "filesize"
                  },
                  on: {
                    "setter-prop": _vm.setQueryFileSize,
                    "handle-filter-selection": function($event) {
                      return _vm.handleFilterSelection("filesize")
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "filtersModal__applyFiltersButton",
            on: { click: _vm.applyFilters }
          },
          [_vm._v("\n      " + _vm._s(_vm.i18nShowAllResults) + "\n    ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }