var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchResult" },
    [
      _c(
        "div",
        { staticClass: "searchResult__numberOfResultsWrapper" },
        [
          _c("numberOfResults", {
            staticClass: "searchResult__numberOfResults"
          }),
          _vm._v(" "),
          _vm.shouldDisplayFiltersModal
            ? _c(
                "button",
                {
                  class: [
                    "searchResult__filtersModalButton",
                    {
                      "searchResult__filtersModalButton--disabled":
                        _vm.searchInProgress
                    }
                  ],
                  attrs: { disabled: _vm.searchInProgress },
                  on: { click: _vm.handleFiltersModalOpen }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.filtersModalButtonText) + "\n      "
                  ),
                  _c("icon", {
                    staticClass: "searchResult__filtersModalButtonIcon",
                    attrs: { name: "search", scale: "60" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldDisplayFiltersModal ? _c("filtersModal") : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLarge
        ? _c("filterPanel", { staticClass: "searchResult__filterPanel" })
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldDisplaySearchResultList
        ? _c(
            "div",
            [
              _c("resultsPerPage", {
                staticClass: "searchResult__resultsPerPage"
              }),
              _vm._v(" "),
              _c("searchResultList", {
                staticClass: "searchResult__searchResultList"
              }),
              _vm._v(" "),
              !_vm.searchInProgress
                ? _c("paginationComponent", {
                    staticClass: "searchResult__paginationComponent"
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            [
              _c("nothingFoundSearchResult", {
                staticClass: "searchResult__nothingFoundSearchResult"
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }