const { version } = require('../../tools/webpack/constants');

const config = {
  assets: `./`,
  version,
  i18nWarnings: false,
  defaultLanguage: 'en',
  availableLanguages: ['bg', 'cn', 'cz', 'de', 'dk', 'en', 'es', 'fi', 'fr', 'gr', 'hr', 'hu', 'it', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'se', 'sk', 'sl', 'tr'],
};

export { config };