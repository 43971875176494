import { mapGetters } from 'vuex';

const i18nComputedProperties = {
  i18nFirstPartPhrase() {
    return this.$t('downloadcenter.search.numberofresults.firstpart', 'Ihre Suche nach');
  },
  i18nSecondPartPhrase() {
    return this.$t('downloadcenter.search.numberofresults.secondpart', 'ergab 0 Ergebnisse', {'0': this.foundAssets});
  },
  i18nLoadingPhrase() {
    return this.$t('downloadcenter.search.numberofresults.loading');
  },
};

export default {
  computed: {
    ...mapGetters('searchModule', ['currentSearch', 'foundAssets', 'searchInProgress']),
    ...i18nComputedProperties,
    queryString() {
      return this.currentSearch ? `"${this.currentSearch}"` : `...`;
    },
  },
};