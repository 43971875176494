import {mapActions, mapGetters} from "vuex";
import NumberOfResults from "./numberOfResults/numberOfResults.vue";
import FilterPanel from "./filterPanel/filterPanel.vue";
import FiltersModal from "./filtersModal/filtersModal.vue";
import SearchResultList from "./searchResultList/searchResultList.vue";
import NothingFoundSearchResult from "./nothingFoundSearchResult/nothingFoundSearchResult.vue";
import ResultsPerPage from "./resultsPerPage/resultsPerPage.vue";
import PaginationComponent from "./paginationComponent/paginationComponent.vue";

const i18nComputedProperties = {
  i18nPrev() {
    return this.$t('downloadcenter.nav.pagination.prev', 'vorherige');
  },
  i18nNext() {
    return this.$t('downloadcenter.nav.pagination.next', 'nächste');
  },
  filtersModalButtonText() {
    return this.$t('downloadcenter.search.filtersmodal.buttontext', 'Filter');
  },
};

export default {
  components: {
    NumberOfResults,
    FilterPanel,
    SearchResultList,
    NothingFoundSearchResult,
    ResultsPerPage,
    PaginationComponent,
    FiltersModal,
  },
  computed: {
    ...mapGetters('searchModule', ['foundAssets', 'currentPage', 'amountPages', 'searchInProgress']),
    ...mapGetters('viewportModule', ['isXSmall', 'isSmall', 'isLarge']),
    ...i18nComputedProperties,
    page: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.setCurrentPage(value);
      },
    },
    shouldDisplaySearchResultList() {
      return this.searchInProgress || this.foundAssets > 0;
    },
    shouldDisplayFiltersModal() {
      return !this.isLarge;
    },
  },
  methods: {
    ...mapActions('searchModule', ['setCurrentPage']),
    ...mapActions('interactionsModule', ['setIsFiltersModalActive']),
    handleFiltersModalOpen() {
      this.setIsFiltersModalActive(true);
    },
  },
};