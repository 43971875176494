import { mapGetters } from 'vuex';

const i18nComputedProperties = {
  i18nNoResultsPhrase() {
    return this.$t('downloadcenter.search.noresults');
  },
  i18nNoCategorySearchPhrase() {
    return this.$t('downloadcenter.search.noresults.nocategorysearch');
  },
};

export default {
  computed: {
    ...mapGetters('searchModule', ['currentSearch', 'queryCategory']),
    ...i18nComputedProperties,
    shouldShowNoCategorySearchPhrase() {
      return !this.currentSearch && this.queryCategory?.length;
    },
  },
};