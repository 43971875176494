import Vue from 'vue';
import { i18nService } from '@/services/i18nService';
import { getPreferredLanguage, setPreferredLanguage } from '@/utils/language';

const initialState = {
  language: 'en',
};

const mutations = {
  UPDATE_I18N(state, payload) {
    Vue.set(state, 'language', payload);
  },
};

const actions = {
  async initI18n({ commit, dispatch }) {
    const language = getPreferredLanguage();
    const isLanguageInState = Vue.i18n.localeExists(language);
    if (!isLanguageInState) { await dispatch('fetchI18n', language); }
    Vue.i18n.set(language);
    if (language !== 'en') { await dispatch('fetchI18n', 'en'); } //we need it to use the fallback language correctly
    Vue.i18n.fallback('en');
    commit('UPDATE_I18N', language);
  },
  async changeI18n({ commit, dispatch }, language) {
    const isLanguageInState = Vue.i18n.localeExists(language);
    if (!isLanguageInState) { await dispatch('fetchI18n', language); }
    Vue.i18n.set(language);
    setPreferredLanguage(language);
    commit('UPDATE_I18N', language);
  },
  async fetchI18n({}, language) {
    const translations = await i18nService(language);
    Vue.i18n.add(language, translations);
  },
};

const getters = {
  language: ({ language }) => language,
};

const i18nModule = {
  state: initialState,
  namespaced: true,
  mutations,
  actions,
  getters,
};

export { i18nModule };