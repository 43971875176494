import {mapGetters} from "vuex";
import SpinnerSVG from '@/assets/icons/spinner.svg';

const i18nComputedProperties = {
  i18nOpenAsset() {
    return this.$t('downloadcenter.search.result.open', 'Öffnen');
  },
};

export default {
  components: {
    SpinnerSVG,
  },
  data() {
    return {
      fileSizePrefixes: " kMGTPEZY",
    };
  },
  computed: {
    ...mapGetters('searchModule', ['result', 'searchInProgress']),
    ...mapGetters('viewportModule', ['isMedium', 'isLarge']),
    ...mapGetters('interactionsModule', ['isFiltersModalActive']),
    ...i18nComputedProperties,
    shouldShowSearchResultHeader() {
      return this.isMedium || this.isLarge;
    },
    fields() {
      return [
        {
          key: 'title',
          label: this.$t('downloadcenter.search.result.header.title'),
        }, {
          key: 'productId',
          label: this.$t('downloadcenter.search.result.header.articlenumber'),
        }, {
          key: 'language',
          label: this.$t('downloadcenter.search.result.header.language'),
        }, {
          key: 'categories',
          label: this.$t('downloadcenter.search.result.header.type'),
        }, {
          key: 'fileSize',
          label: this.$t('downloadcenter.search.result.header.downloadsize'),
        }, {
          key: 'assetLink',
          label: '',
        },
      ];
    },
  },
  methods: {
    convertToHumanReadableFileSize(fileSize) {
      let divider = 1;
      for (const fileSizePrefixIndex of this.fileSizePrefixes) {
        const quotient = fileSize / divider;
        divider = divider * 1000;

        if (quotient < 1000) {
          const roundedQuotient = Math.round(quotient * 100) / 100;
          return `${roundedQuotient} ${fileSizePrefixIndex}B`;
        }
      }
    },
    getCategoryTranslation(categories) {
      const categoryTranslations = [];
      for (const category of categories) {
        categoryTranslations.push(this.$t(`downloadcenter.search.documents.${category.toLowerCase()}`));
      }
      return categoryTranslations.join(" / ");
    },
    getFlagUri(language) {
      const flag = '##' === language ? 'eu' : language;
      return require(`../../../assets/flags/${flag}.png`);
    },
    getLanguage(language) {
      return this.$t(`downloadcenter.search.languages.${language}`);
    },
  },
};