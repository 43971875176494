var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["searchResultList", { "no-scroll": _vm.isFiltersModalActive }] },
    [
      _c("div", { staticClass: "searchResultList__table" }, [
        _vm.shouldShowSearchResultHeader
          ? _c("header", { staticClass: "searchResultList__header" }, [
              _c(
                "div",
                {
                  class: [
                    "searchResultList__headerRow",
                    {
                      "searchResultList__headerRow--disabled":
                        _vm.searchInProgress
                    }
                  ]
                },
                _vm._l(_vm.fields, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.key,
                      class: [
                        "searchResultList__headerCell",
                        "searchResultList__cell" +
                          (item.key[0].toUpperCase() + item.key.slice(1))
                      ],
                      attrs: {
                        "aria-colindex": index + 1,
                        role: "columnheader",
                        scope: "col"
                      }
                    },
                    [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.searchInProgress
          ? _c(
              "div",
              { staticClass: "searchResultList__body" },
              _vm._l(_vm.result, function(item, index) {
                return _c(
                  "div",
                  { key: "row" + index, staticClass: "searchResultList__row" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "searchResultList__cell searchResultList__cellTitle"
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.title) + "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "searchResultList__cell" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.productId) + "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "searchResultList__cell searchResultList__cellLanguage"
                      },
                      [
                        _c("img", {
                          staticClass: "searchResultList__languageFlag",
                          attrs: {
                            src: _vm.getFlagUri(item.language),
                            alt: _vm.getLanguage(item.language),
                            title: _vm.getLanguage(item.language)
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "searchResultList__cell" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getCategoryTranslation(item.categories)) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "searchResultList__cell" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.convertToHumanReadableFileSize(item.fileSize)
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "searchResultList__cell searchResultList__cellAssetLink"
                      },
                      [
                        _c(
                          "a",
                          { attrs: { href: item.assetLink, target: "_blank" } },
                          [
                            _c("icon", {
                              staticClass: "searchResultList__downloadIcon",
                              attrs: {
                                title: _vm.i18nOpenAsset,
                                alt: _vm.i18nOpenAsset,
                                name: "download"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.searchInProgress
        ? _c("div", { staticClass: "searchResultList__loadingSpinner" }, [
            _vm._v("\n    Loading...\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }