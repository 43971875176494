import { SVGtoArray } from './utils/parse';

/**
 * Icon
 * * https://github.com/cenkai88/vue-svg-icon
 */
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    scale: [Number, String],
    spin: Boolean,
  },
  computed: {
    normalizedScale() {
      let scale = this.scale;
      scale = typeof scale === 'undefined' ? 1 : Number(scale);
      if (isNaN(scale) || scale <= 0) {
        return 1;
      }
      return scale;
    },
    icon() {
      let xml = require(`!xml-loader!../../../assets/icons/${this.name}.svg`);
      const t = xml.svg.$.viewBox.split(' ');
      return {
        width: t[2],
        height: t[3],
        paths: SVGtoArray(xml.svg),
      };
    },
    box() {
      return `0 0 ${this.icon.width} ${this.icon.height}`;
    },
    width() {
      return (this.icon.width / 112) * this.normalizedScale;
    },
    height() {
      return (this.icon.height / 112) * this.normalizedScale;
    },
    style() {
      if (this.normalizedScale === 1) {
        return false;
      }
      return {
        fontSize: this.normalizedScale + 'em',
      };
    },
  },
};
