var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filterPanel" }, [
    _c("div", { staticClass: "filterPanel__selectsPanelContainer" }, [
      _c("div", { staticClass: "filterPanel__selectsTitle" }, [
        _vm._v("\n      " + _vm._s(_vm.i18nFilterPanelTitleText) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filterPanel__selectsWrapper" },
        [
          _c("filterSelect", {
            staticClass: "filterPanel__select",
            attrs: {
              "filter-name": _vm.i18nCategoriesFilterName,
              "options-list": _vm.categoryTranslationKeys,
              "getter-prop": _vm.queryCategory,
              disabled: _vm.searchInProgress
            },
            on: { "setter-prop": _vm.setQueryCategory }
          }),
          _vm._v(" "),
          _c("filterSelect", {
            staticClass: "filterPanel__select",
            attrs: {
              "filter-name": _vm.i18nLanguagesFilterName,
              "options-list": _vm.languageTranslationKeys,
              "getter-prop": _vm.queryLanguage,
              disabled: _vm.searchInProgress
            },
            on: { "setter-prop": _vm.setQueryLanguage }
          }),
          _vm._v(" "),
          _c("filterSelect", {
            staticClass: "filterPanel__select",
            attrs: {
              "filter-name": _vm.i18nFileSizeFilterName,
              "options-list": _vm.fileSizeTranslationKeys,
              "getter-prop": _vm.queryFileSize,
              disabled: _vm.searchInProgress
            },
            on: { "setter-prop": _vm.setQueryFileSize }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filterPanel__filterTagsContainer" },
      [
        _vm.hasTags
          ? _c("filterTag", {
              staticClass: "filterPanel__filterTag",
              attrs: { "tag-name": _vm.i18nClearAllTags, "active-tag": "" },
              on: { "remove-tag": _vm.clearAllTags }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.categoryTagsArray, function(tagName, index) {
          return _c("filterTag", {
            key: "category" + index,
            staticClass: "filterPanel__filterTag",
            attrs: { "tag-name": tagName.value },
            on: {
              "remove-tag": function($event) {
                return _vm.deleteQueryCategory(tagName.key)
              }
            }
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.languageTagsArray, function(tagName, index) {
          return _c("filterTag", {
            key: "language" + index,
            staticClass: "filterPanel__filterTag",
            attrs: { "tag-name": tagName.value },
            on: {
              "remove-tag": function($event) {
                return _vm.deleteQueryLanguage(tagName.key)
              }
            }
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.fileSizeTagsArray, function(tagName, index) {
          return _c("filterTag", {
            key: "fileSize" + index,
            staticClass: "filterPanel__filterTag",
            attrs: { "tag-name": tagName.value },
            on: {
              "remove-tag": function($event) {
                return _vm.deleteQueryFileSize(tagName.key)
              }
            }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }