var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paginationComponent" },
    [
      _c(
        "div",
        {
          class: [
            "paginationComponent__adjacentPageButton",
            {
              "paginationComponent__adjacentPageButton--disabled": !_vm.shouldShowPrevPageButton
            }
          ],
          on: { click: _vm.handlePrevPageClick }
        },
        [_vm._v("\n    " + _vm._s(_vm.i18nPrevPageText) + "\n  ")]
      ),
      _vm._v(" "),
      _c("paginationItem", {
        staticClass: "paginationComponent__item",
        attrs: { "page-number": 1, "active-page": 1 === _vm.currentPage },
        on: {
          "set-current-page": function($event) {
            return _vm.setCurrentPage(1)
          }
        }
      }),
      _vm._v(" "),
      _vm.shouldShowFirstDots
        ? _c("div", { staticClass: "paginationComponent__dots" }, [
            _vm._v("\n    ...\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.paginationItemsList, function(item) {
        return _c("paginationItem", {
          key: "page" + item,
          staticClass: "paginationComponent__item",
          attrs: {
            "page-number": item,
            "active-page": item === _vm.currentPage
          },
          on: {
            "set-current-page": function($event) {
              return _vm.setCurrentPage(item)
            }
          }
        })
      }),
      _vm._v(" "),
      _vm.shouldShowLastDots
        ? _c("div", { staticClass: "paginationComponent__dots" }, [
            _vm._v("\n    ...\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowPagination
        ? _c("paginationItem", {
            staticClass: "paginationComponent__item",
            attrs: {
              "page-number": _vm.amountPages,
              "active-page": _vm.amountPages === _vm.currentPage
            },
            on: {
              "set-current-page": function($event) {
                return _vm.setCurrentPage(_vm.amountPages)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "paginationComponent__adjacentPageButton",
            {
              "paginationComponent__adjacentPageButton--disabled": !_vm.shouldShowNextPageButton
            }
          ],
          on: { click: _vm.handleNextPageClick }
        },
        [_vm._v("\n    " + _vm._s(_vm.i18nNextPageText) + "\n  ")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }