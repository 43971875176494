var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "numberOfResults" }, [
    _vm._v("\n  " + _vm._s(_vm.i18nFirstPartPhrase) + " "),
    _c("span", { staticClass: "--conrad-blue" }, [
      _vm._v(_vm._s(_vm.queryString))
    ]),
    _vm._v(" "),
    !_vm.searchInProgress
      ? _c("span", [
          _vm._v("\n    " + _vm._s(_vm.i18nSecondPartPhrase) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.searchInProgress
      ? _c("span", [_vm._v("\n    " + _vm._s(_vm.i18nLoadingPhrase) + "\n  ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }