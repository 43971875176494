import axios from "axios";
import { store } from '@/store';
import { IS_PROD_DOMAIN } from '@/utils/constants';
const BACKEND_URL = `https://api.${IS_PROD_DOMAIN ? 'conrad.de' : 'de.preprod.tds-np.com'}/dlc/1/dlc-mw`;
const API_KEY = IS_PROD_DOMAIN ? 'LmHo6g67Lds5zyBySp4cvJEhTmxKShHf' : 'JW3npeWTWu2BeNY5myFlptA7iv23RJwa';

const getAssets = async () => {
  const queryLanguageString = store.getters['searchModule/queryLanguage'].join(',');
  const queryCategoryString = store.getters['searchModule/queryCategory'].join(',');
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      apikey: API_KEY,
      query: store.getters['searchModule/query'],
      searchMode: store.getters['searchModule/searchMode'],
      languages: queryLanguageString,
      itemsPerPage: store.getters['searchModule/itemsPerPage'],
      offset: store.getters['searchModule/offset'],
      category: queryCategoryString,
      fileSizeMin: store.getters['searchModule/fileSizeMin'],
      fileSizeMax: store.getters['searchModule/fileSizeMax'],
    },
    data: {},
  };

  return axios.get(BACKEND_URL, config);
};

export default {getAssets};