var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filtersListItem" }, [
    _c(
      "div",
      {
        staticClass: "filtersListItem__filterHeader",
        on: {
          click: function($event) {
            return _vm.$emit("handle-filter-selection")
          }
        }
      },
      [
        _c("span", { staticClass: "filtersListItem__filterName" }, [
          _c("span", [_vm._v(_vm._s(_vm.filterName))]),
          _vm._v(" "),
          _vm.filtersArray && !_vm.isFilterSelected
            ? _c("span", { staticClass: "filtersListItem__selectedFilters" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.selectedFiltersList) + "\n      "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.isFilterSelected
          ? _c("icon", {
              staticClass: "filtersListItem__arrowIcon",
              attrs: { name: "arrow-down", scale: "100" }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isFilterSelected
          ? _c(
              "span",
              {
                staticClass: "filtersListItem__filterReset",
                on: { click: _vm.resetFilter }
              },
              [
                _vm._v(
                  " \n      " + _vm._s(_vm.i18nFilterHeaderReset) + "\n    "
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.isFilterSelected
      ? _c(
          "div",
          { staticClass: "filtersListItem__optionsContainer" },
          _vm._l(_vm.optionsList, function(option, index) {
            return _c(
              "div",
              { key: index, staticClass: "filtersListItem__option" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.vModelFromProps,
                      expression: "vModelFromProps"
                    }
                  ],
                  attrs: { id: option.key, type: "checkbox" },
                  domProps: {
                    value: option.key,
                    checked: Array.isArray(_vm.vModelFromProps)
                      ? _vm._i(_vm.vModelFromProps, option.key) > -1
                      : _vm.vModelFromProps
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.vModelFromProps,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = option.key,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.vModelFromProps = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.vModelFromProps = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.vModelFromProps = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "filtersListItem__label",
                    attrs: { for: option.key }
                  },
                  [
                    _vm._v("\n        " + _vm._s(option.value) + "\n        "),
                    _c("icon", {
                      class: [
                        "filtersListItem__labelIcon",
                        { "--conrad-blue": _vm.isCheckboxSelected(option.key) }
                      ],
                      attrs: {
                        name: _vm.isCheckboxSelected(option.key)
                          ? "checkbox-checked"
                          : "checkbox-unchecked",
                        scale: "89"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }